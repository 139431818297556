import type { HealthProfessional } from "~/types/strapi/api/health-professional/content-types/health-professional/health-professional";
import type { Service } from "~/types/strapi/api/service/content-types/service/service";
import { isNotNullOrUndefined } from "~/utils/typeGuards";

export function getServicesFromHealthProfessionalsByUuid(
  healthProfessionalData: HealthProfessional[],
  uuid: string,
): Service[] {
  const allServices = healthProfessionalData.flatMap((healthProfessional) => {
    return healthProfessional.attributes?.services?.data;
  });

  const uniqueServices = [...new Map(allServices.map((item) => [item?.id, item])).values()];

  const foundServices = uniqueServices.filter((service) => {
    const groupData = service?.attributes.group?.data;

    return service?.attributes.uuid === uuid || groupData?.attributes.uuid === uuid;
  });

  return foundServices.filter(isNotNullOrUndefined);
}
