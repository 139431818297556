import type { HealthProfessional } from "~/types/strapi/api/health-professional/content-types/health-professional/health-professional";
import type { Service } from "~/types/strapi/api/service/content-types/service/service";
import { getServicesFromHealthProfessionalsByUuid } from "~/utils/getServicesFromHealthProfessionalsByUuid";

export function getServiceFromHealthProfessional(
  healthProfessionalData: HealthProfessional | HealthProfessional[],
  uuid: string,
): Service | undefined {
  const healthProfessionalArray = Array.isArray(healthProfessionalData)
    ? healthProfessionalData
    : [healthProfessionalData];

  const services = getServicesFromHealthProfessionalsByUuid(healthProfessionalArray, uuid);

  return services[0];
}
